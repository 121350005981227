
.close-icon {
    transition: 0.3s;
    border-radius: 50px !important;
    margin-top: 20px;
    margin-right: 15px;
    padding: 3px;

    &:hover {
        background-color: #2c423d;
        color: white;
    }
}

.tract-container {
    border: 1px dashed rgba(120, 117, 106, 0.5);
    padding: 1rem;
    border-radius: 5px;
}
